import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

import type { RowProps } from "../";
import { Box, Column, GRID_PADDING, GUTTER, Row, Stage } from "../";

/**
 * This file contains extensions/use-cases of the old @evernest/grid package
 * That package was migrated to src/design-system/organisms/grid in EDP-3540
 * The @evernest were deprecated
 */

export const FlexRow = styled(Row)`
	flex: 1;
`;

export const RelativeStage = styled(Stage)`
	position: relative;
`;

export const Flex = styled.div`
	position: relative;
	flex: 1;
`;

export const RelativeColumn = styled(Column)`
	position: relative;
`;

export const RelativeColumnRounded = styled(RelativeColumn)`
	overflow: hidden;
	${({ theme: { tokens } }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

export const RelativeRow = styled(Row)`
	position: relative;
`;

export const BlockRow = styled(Row)`
	display: block;
`;

export const RelativeBox = styled(Box)`
	position: relative;
`;

export const AbsoluteRow = styled(Row)`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
`;

export const ScrollableRow = styled(Row)<PropsWithTheme & RowProps>`
	flex-wrap: nowrap;
	padding-bottom: 20px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: x mandatory;
	scroll-padding: 0 calc(var(${GUTTER}) * 1px + var(${GRID_PADDING}) * 1px);
	clip-path: polygon(0 0, 0 calc(100% - 20px), 100% calc(100% - 20px), 100% 0);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			padding-bottom: 0;
			clip-path: none;
			scroll-snap-type: unset;
			flex-wrap: wrap;
			overflow-x: visible;
			-webkit-overflow-scrolling: unset;
		}
	`};
`;

export const SnapColumn = styled(Column)`
	scroll-snap-align: start;
`;

// Where above "l" we want to switch back to normal Grid Column behaviour
export const SnapColumnMobile = styled(SnapColumn)<PropsWithTheme>`
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			width: calc(
				100% / var(--grid-colcount) * var(--grid-colspan) - var(--grid-gutter) * 2px
			);
		}
	`};
`;

export const CenterColumnL = styled(Column)<PropsWithTheme>`
	${({ theme }) => css`
		@media only screen and ${theme.mq.l} {
			text-align: center;
		}
	`}
`;

export const CenterColumn = styled(Column)<PropsWithTheme>`
	text-align: center;
`;
